:root {
    --color-1: rgba(248, 248, 248, 1);
    --color-2: rgba(142, 142, 142, 1);
    --color-3: rgba(142, 142, 142, 0.5);
    --color-4: rgba(191, 21, 21, 1);
    --color-5: rgba(33, 255, 228, 1);
    --color-6: rgba(33, 255, 228, 0.5);
    --color-7: rgba(0, 0, 0, 1);
    --color-8: rgba(0, 0, 0, 0.6);
    --color-9: rgba(75, 23, 23, 1);

    --border-size: 2px;
    --edges-size: 8px;

    background-image: url("../public/back.png");
    background-attachment: fixed;
    background-size: cover;
    background-position: center;

    overflow: auto;

    color: var(--color-1);
}

#root {
    width: 100vw;
    height: 100vh;
    padding: 30px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.w-64 {
    width: 64px;
}

.w-100 {
    width: 100%;
}

.gap-16 {
    gap: 16px;
}

.has-text-danger {
    color: var(--color-4) !important;
}

.has-text-danger .link {
    color: var(--color-4);
}

.has-text-danger .link:hover {
    color: var(--color-4);
}

.login-form {
    max-width: 448px;
}

.username, .license-header {
    color: var(--color-1);
    font-weight: 600;
    font-size: 1.5rem;
}

.license-header > img {
    cursor: pointer;
}

.input {
    border: 1px solid var(--color-1);
    border-radius: 0;
    background-color: rgba(30, 30, 30, 1);
    color: var(--color-1);
}

.input::placeholder {
    color: var(--color-2);
}

/* Autofill fix */
&:-webkit-autofill::first-line,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif !important;
}

.copyable {
    position: relative;
}

.copyable > .copy-icon {
    position: absolute;
    top: 50%;
    right: 4px;
    transform: translate(0, -50%);
}

.copyable > .input {
    padding-right: 28px;
}

.select select {
    border-radius: 0;
    background-color: rgba(30, 30, 30, 1);
    color: var(--color-1);
}

.select::after {
    border-color: var(--color-1) !important;
}

.select,
.select select {
    min-width: 0;
    max-width: 100%;
    width: 100%;
}

.button {
    background-color: var(--color-8);
    color: var(--color-1);
    border: none;
    border-radius: 0;

    /* Edges */

    --main-border-color: transparent;
    --secondary-border-color: transparent;

    box-sizing: border-box;

    clip-path: polygon(
        var(--edges-size) 0,
        0 var(--edges-size),
        0% 100%,
        calc(100% - var(--edges-size)) 100%,
        100% calc(100% - var(--edges-size)),
        100% 0
    );

    position: relative;
    border: var(--border-size) solid var(--main-border-color) !important;
}

.button::before,
.button::after {
    --position: calc(-0.7 * var(--edges-size) - 0.3 * var(--border-size));
    content: "";
    display: block;
    width: calc(1.41 * var(--edges-size));
    height: calc(1.41 * var(--edges-size));
    transform: rotate(45deg);
    position: absolute;
    box-sizing: border-box;
}

.button::before {
    border-right: var(--border-size) solid var(--secondary-border-color);
    top: var(--position);
    left: var(--position);
}

.button::after {
    border-left: var(--border-size) solid var(--secondary-border-color);
    bottom: var(--position);
    right: var(--position);
}

.button:hover,
.button:focus {
    --main-border-color: var(--color-6);
    --secondary-border-color: var(--color-5);
    color: var(--color-5);
}

.button.with-borders {
    --main-border-color: var(--color-6);
    --secondary-border-color: var(--color-5);
}

.button.login-button {
    background-color: var(--color-5);
    border-radius: 0;
    border: none;
    text-transform: uppercase;
    font-weight: 700;
    color: var(--color-7);
    clip-path: none;
}

.button.button.login-button::before,
.button.button.login-button::after {
    content: unset;
}

.button.big {
    display: block;
    width: 448px;
    height: 100px;
    text-align: center;
    font-size: 1.65rem;
}

.button.logout-button {
    --main-border-color: var(--color-9);
    --secondary-border-color: var(--color-9);
    width: 141px;
    height: 58px;
    text-transform: uppercase;
}

.button.logout-button:hover {
    --main-border-color: var(--color-9);
    --secondary-border-color: var(--color-9);
}

.checkbox {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    
    width: 1.5rem;
    height: 1.5rem;
    position: relative;

    border: 1px solid var(--color-1);
    border-radius: 0;
    background-color: rgba(30, 30, 30, 1);
    color: var(--color-1);
}

.checkbox::before {
    content: "";
    display: block;
    margin: 4px;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    background-color: var(--color-1);
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

.checkbox:checked::before {
    transform: scale(1);
}

.table {
    text-align: center !important;
    vertical-align: middle !important;
    color: var(--color-1);
    background-color: transparent;
}

.table.small {
    font-size: 0.75rem;
}

.table > thead > tr > th {
    position: sticky;
    top: -1px;
    color: var(--color-1);
    background-color: var(--color-8);
    text-align: center !important;
    vertical-align: middle;
    border: none;
    color: var(--color-1);
    font-weight: normal;
}

.table td {
    border: solid var(--color-3);
    border-width: 0 0 1px 0;
    vertical-align: middle;
}

.table-container {
    overflow: auto;
}

.table-container::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.table-container::-webkit-scrollbar-track {
    background-color: var(--color-8);
}

.table-container::-webkit-scrollbar-thumb {
    background-color: var(--color-5);
}

.link,
.breadcrumb .link {
    color: var(--color-1);
    text-decoration: underline;
}

.link:hover,
.breadcrumb .link:hover {
    color: var(--color-5);
}

.breadcrumb .is-active .link {
    color: var(--color-2);
}

button.link {
    display: inline;
    background: none;
    border: none;
    cursor: pointer;
}

.pagination > button {
    background-color: rgba(30, 30, 30, 0.8);
    --current-color: var(--color-2);
    color: var(--current-color);
    border: 1px solid var(--color-2);
    border-radius: 0;
    font-size: 0.75rem;
    min-width: 23px;
    min-height: 26px;
    cursor: pointer;
}

.pagination > button:hover {
    --current-color: var(--color-1);
}

.pagination > button.previous::before,
.pagination > button.next::after {
    content: "";
    display: inline-block;
    width: 0.31rem;
    height: 0.31rem;
    border: solid var(--current-color);
    transform: rotate(45deg) translate(-0.1em, -0.1em);
    margin: auto 0.1rem;
}

.pagination > button.previous::before {
    border-width: 0 0 1px 1px;
}

.pagination > button.next::after {
    border-width: 1px 1px 0 0;
}

.pagination > button.page:not(.first),
.pagination > .dots {
    border-left: 0;
}

.pagination > .current {
    --current-color: var(--color-1);
    background-color: var(--color-6);
    border-color: transparent;
}

.pagination {
    position: relative;
}

.jump {
    position: absolute;
    bottom: calc(100% + 7px);
    color: var(--color-1);
    text-align: left;
    background-color: var(--color-8);
    border-bottom: 2px solid var(--color-5);
    padding: 9px;
    width: max-content;
    right: 30px;
    font-size: 0.75rem;
}

.jump :not(form) {
    margin: 5px;
}

.jump input {
    color: var(--color-1);
    background-color: transparent;
    border: 1px solid var(--color-1);
    width: 93px;
}

.jump input:focus {
    outline: 0;
}

.jump button {
    color: var(--color-1);
    background-color: transparent;
    border: 1px solid var(--color-6);
}

.modal-dialog {
    --error-color-1: rgba(191, 21, 21, 0.8);
    --error-color-2: rgba(75, 23, 23, 0.8);

    background-color: var(--color-8);
    padding: 10px;

    width: 664px;
    max-width: 100%;
    min-height: 242px;

    color: var(--color-1);
    text-align: center;
    border: 2px solid transparent;
    position: relative;
}

.modal-dialog,
.modal-dialog .button {
    --border-size: 3.75px;
    --edges-size: 21px;

    font-size: 1.875rem;
    font-weight: bold;
    text-align: center;
}

.modal-dialog .button {
    width: 176px;
    height: 73px;
}

.modal-dialog::after,
.modal-dialog::before {
    content: "";
    display: block;
    position: absolute;
    width: 94px;
    height: 94px;
    border: solid var(--error-color-1);
}
.modal-dialog::before {
    border-width: 0 0 2px 2px;
    left: -1.5px;
    bottom: -2px;
    border-image: radial-gradient(
            circle at left bottom,
            var(--error-color-1) 11%,
            var(--error-color-2) 11%
        )
        1;
}
.modal-dialog::after {
    border-width: 2px 2px 0 0;
    right: -1.5px;
    top: -2px;
    border-image: radial-gradient(
            circle at top right,
            var(--error-color-1) 11%,
            var(--error-color-2) 11%
        )
        1;
}

.broken {
    width: 37px;
    height: 37px;
    position: absolute;
    bottom: -19px;
    left: -19px;
    box-sizing: border-box;
    background: var(--error-color-2);
    border: solid var(--error-color-1);
    border-width: 0 2px 0 0;
}

.quadro-navbar {
    height: 50px;
}

.quadro-navbar img, .quadro-navbar a {
    height: 100% !important;
}